import React, { useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useQuery } from "@tanstack/react-query";

import LoadingError from "~/components/Loaders/LoadingError";
import AddTimeblock from "~/components/Timeblocks/AddTimeBlock";
import NewDisplayTimeblock from "~/components/Timeblocks/NewDisplayTimeblock";
import nurseService from "~/utils/api/v1/nurseService";
import nurseAPI from "~/utils/api/v2/nurse";
import sectorsAPI from "~/utils/api/v2/places";
import { getAllCountryTimeblocks, getNurseTimeblocks } from "~/utils/api/v2/timeblocks";
import type { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";
import { MedicalAction, MedicalService } from "~/utils/interfaces/Services";

function NurseSchedule({ nurseId }: { nurseId: string }) {
  const [country, setCountry] = useState<Locale | null>(null);
  const [error, setError] = useState<string>("");
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [gridName, setGridName] = useState<string>("");

  const {
    data: nurseData,
    isLoading: isNurseLoading,
    error: nurseError,
  } = useQuery({
    queryKey: ["nurse", nurseId],
    queryFn: async () => {
      const response = await nurseService.fetchNurseProfile(nurseId);
      return response.data as Nurse;
    },
  });

  useEffect(() => {
    const newGridName = { regular: "", operative: "operatives_cl_15" };
    setGridName(newGridName[(nurseData?.type_of_appointment || "regular") as keyof typeof newGridName]);
  }, [nurseData]);

  const {
    data: sectorsData = [],
    isLoading: isSectorsLoading,
    error: sectorsError,
  } = useQuery({
    queryKey: ["sectors", nurseId],
    queryFn: async () => sectorsAPI.fetchNurseSectors(nurseId),
  });

  const {
    data: allTimeblocksData = [],
    isLoading: isTimeblocksLoading,
    error: timeblocksError,
  } = useQuery({
    queryKey: ["allTimeblocks", country, gridName],
    queryFn: async () => getAllCountryTimeblocks(country!, gridName),
    enabled: !!country,
  });

  const {
    data: nurseTimeblocksData = { blocked: [], timeblocks: [] },
    isLoading: isNurseTimeblocksLoading,
    error: nurseTimeblocksError,
    refetch: refetchNurseTimeblocks,
  } = useQuery({
    queryKey: ["nurseTimeblocks", nurseId],
    queryFn: async () => {
      const response = await getNurseTimeblocks(nurseId);
      return response.data;
    },
  });

  const {
    data: medicalActionsData = [],
    isLoading: isMedicalActionsLoading,
    error: medicalActionsError,
  } = useQuery({
    queryKey: ["medicalActions", nurseId],
    queryFn: async () => {
      const response = await nurseAPI.listMedicalActions(nurseId);
      return response.data as MedicalAction[];
    },
  });

  const {
    data: medicalServicesData = [],
    isLoading: isMedicalServicesLoading,
    error: medicalServicesError,
  } = useQuery({
    queryKey: ["medicalServices", nurseId],
    queryFn: async () => {
      const response = await nurseAPI.listMedicalServices(nurseId);
      return response.data as MedicalService[];
    },
  });

  const isLoading =
    isNurseLoading ||
    isSectorsLoading ||
    isTimeblocksLoading ||
    isNurseTimeblocksLoading ||
    isMedicalActionsLoading ||
    isMedicalServicesLoading;

  useEffect(() => {
    if (nurseData) {
      setCountry(nurseData.country as Locale);
    }
  }, [nurseData]);

  useEffect(() => {
    if (nurseError) {
      setError(nurseError.message || "Error fetching nurse");
    } else if (sectorsError) {
      setError(sectorsError.message || "Error fetching sectors");
    } else if (timeblocksError) {
      setError(timeblocksError.message || "Error fetching timeblocks");
    } else if (nurseTimeblocksError) {
      setError(nurseTimeblocksError.message || "Error fetching nurse timeblocks");
    } else if (medicalActionsError) {
      setError(medicalActionsError.message || "Error fetching medical actions");
    } else if (medicalServicesError) {
      setError(medicalServicesError.message || "Error fetching medical services");
    }
  }, [nurseError, sectorsError, timeblocksError, nurseTimeblocksError, medicalActionsError, medicalServicesError]);

  return (
    <>
      <h1 className="text-4xl font-light">
        {nurseData?.names} {nurseData?.last_names}
      </h1>
      <div className="flex flex-col bg-white p-6 mt-4">
        <LoadingError
          loading={isLoading}
          error={error}
        />
        {!isLoading && !error && (
          <>
            <h1 className="text-4xl font-light">
              Horarios actuales
              {!showAdd && (
                <span
                  onClick={() => setShowAdd(true)}
                  className="cursor-pointer ml-4"
                >
                  {" "}
                  <AddCircleIcon />
                </span>
              )}
            </h1>
            {showAdd && allTimeblocksData && allTimeblocksData.length > 0 && (
              <AddTimeblock
                onCancel={() => setShowAdd(false)}
                timeblocks={allTimeblocksData}
                currentTimeblocks={nurseTimeblocksData.timeblocks}
                refreshTimeblocks={refetchNurseTimeblocks}
                nurseId={nurseId}
                sectors={sectorsData}
                medicalActions={medicalActionsData}
                medicalServices={medicalServicesData}
              />
            )}
            <NewDisplayTimeblock
              allTimeblocks={allTimeblocksData}
              activeTimeblocks={nurseTimeblocksData.timeblocks}
              nurseId={nurseId}
              refreshTimeblocks={refetchNurseTimeblocks}
              allowDelete
              countSectors={sectorsData.length}
            />
          </>
        )}
      </div>
    </>
  );
}

export default NurseSchedule;
